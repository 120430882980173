.modal-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .7);
    z-index: $modal-z-index;

    > div.modal {
        display: block;
        margin: auto;
        height: 100%;
        width: 100%;
        background: $white;
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.35);
        overflow: hidden;
        position: relative;

        @include tablet() {
            max-width: 500px;
            margin: 80px auto;
            height: auto;

            &.success {
                max-width: 500px !important;
            }
        }

        &.success {
            height: auto;
            margin: 80px auto;
            max-width: 300px;
        }

        > header {
            background: $primary;
            padding: 0.5em 3em 1.3em 1em;
            display: block;
            color: $white;
            height: auto;
            position: relative;
            text-align: left;

            @include tablet() {
                padding: 1em 3em 2em 2em;
            }

            > section {
                > h2 {
                    font-size: 1.8em;
                    margin: 0.5em 0 0.2em;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                > h3 {
                    margin-bottom: 0;
                }

                > span {
                    font-size: 1.1em;
                    font-weight: 500;

                    &.category {
                        display: block;
                        color: lighten($primary, 20%);
                        font-size: 1.3em;
                        font-weight: 700;
                        margin-bottom: 1.2em;
                    }

                    &.location, &.separator, &.type {
                        color: #fff;
                    }
                }

                > i.close {
                    position: absolute;
                    top: 0.5em;
                    right: 0.5em;
                    cursor: pointer;
                    color: lighten($primary, 15%);
                    font-size: 2em;

                    @include tablet() {
                        display: none;
                    }
                }

                &.success {
                    text-align: center;
                }
            }
        }

        > div.main {
            padding: 1em;
            overflow: auto;
            height: calc(100% - 60px - 120px);

            @include tablet() {
                padding: 2em 2em;
                height: auto;
            }

            section {
                form.apply-form {
                    > div.form-field {
                        margin: 1em 0;

                        @include desktop() {
                            margin: 2em 0;
                        }

                        > div.input {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            > .required {
                                color: $error;
                                font-size: 1.2em;
                            }

                            > input {
                                flex: 1;
                                color: lighten($dark-bg, 30%);

                                &.ng-invalid {
                                    &.ng-valid-required {
                                        color: $error;
                                    }
                                }

                                &[type="text"], &[type="email"] {
                                    border: none;
                                    border-bottom: 1px solid darken($light-bg, 15%);
                                    transition: border-bottom 0.2s ease-in-out;
                                    padding: 0.5em;

                                    &:focus {
                                        border-bottom: 1px solid $primary;
                                    }
                                }
                            }
                        }

                        &.upload-container {
                            border: 1px solid $primary;
                            color: $primary;
                            border-radius: 3px;
                            background: transparent;
                            text-align: center;
                            position: relative;
                            cursor: pointer;
                            font-size: 1.2em;
                            margin-top: 1em;
                            margin-bottom: 1.6em;
                            height: 50px;

                            > i {
                                line-height: 50px;
                                font-size: 1.3em;
                                padding-right: 0.5em;

                                &:before {
                                    vertical-align: inherit;
                                }
                            }

                            > span {
                                line-height: 50px;
                                vertical-align: top;
                            }

                            &.valid {
                                border-color: $secondary;
                                color: $secondary;
                            }
                        }

                        &:first-child {
                            margin-top: 0;
                        }
                    }

                    > div.upload-label {
                        font-size: 1.1em;
                        color: lighten($dark-bg, 30%);

                        > i {
                            color: lighten($dark-bg, 20%);
                        }

                        > .error {
                            margin-top: 0;
                            float: right;
                        }
                    }
                }

                &.success {
                    padding: 1em;
                }

                input {
                    color: lighten($dark-bg, 30%);
                    width: 100%;
                    margin-top: 1em;

                    &.ng-invalid {
                        &.ng-valid-required {
                            color: $error;
                        }
                    }

                    &[type="text"], &[type="email"] {
                        border: none;
                        border-bottom: 1px solid darken($light-bg, 15%);
                        transition: border-bottom 0.2s ease-in-out;
                        padding: 0.5em;

                        &:focus {
                            border-bottom: 1px solid $primary;
                        }
                    }
                }

                // Linked In Integration:
                $linked-in-blue: #0077B5;


                div.alert {
                    $info-blue-font: #245C7E;
                    $info-blue-bg: #D0E8F6;

                    color: $info-blue-font;
                    padding: 1em;
                    margin: 1.5em 0 0;
                    border-radius: .5em;

                    &.info {
                        border: solid thin darken($info-blue-bg, 10%);
                        background-color: $info-blue-bg;
                    }
                }
                textarea {
                    width: 100%;
                    resize: none;
                    border: none;
                    border-bottom: solid thin #CCC;
                    padding: 0 .75em;

                    color: #797c7e;

                    &:focus,
                    &:hover,
                    &:active {
                        outline: none;
                    }

                    &:focus {
                        border-bottom-color: $primary;
                    }
                }

                span.break {
                    position: relative;
                    border-bottom: solid lightgray thin;
                    display: block;
                    //height: 2em;
                    margin: 2em 0;
                    > span {
                        position: absolute;
                        top: -.5em;
                        text-align: center;
                        width: 6%;
                        color: grey;
                        left: 44%;
                        background-color: white;
                        display: block;
                    }
                }

                button.btn-li-lg {
                    display: block;

                    border: none;
                    border-radius: .25em;
                    padding: 0;

                    margin: auto;

                    overflow: hidden;

                    background: $linked-in-blue;
                    color: white;

                    text-align: left;

                    -webkit-transition: background-color 250ms;
                    -moz-transition: background-color 250ms;
                    -ms-transition: background-color 250ms;
                    -o-transition: background-color 250ms;
                    transition: background-color 250ms;

                    &:hover,
                    &:active,
                    &:focus {
                        background-color: darken($linked-in-blue, 5%);
                    }

                    &.done,
                    &.loading {
                        background-color: transparentize($linked-in-blue, .25);
                        color: rgba(255, 255, 255, .75);
                        > i {
                            border-right-color: lighten($linked-in-blue, 10%);
                        }
                    }

                    > i {
                        font-size: 2.5em;
                        padding: .15em .25em;
                        margin: 0 .25em 0 0;
                        display: inline-block;
                        height: 100%;
                        border-right: solid thin darken($linked-in-blue, 7%);
                    }

                    > span {
                        position: relative;
                        top: -.4em;
                        font-size: 1.1em;
                        font-weight: 600;
                        padding: 0 1.5em 0 0;
                    }

                }
            }


        }

        footer {
            width: 100%;
            text-align: right;
            position: fixed;
            bottom: 0;
            left: 0;
            height: 50px;

            display: flex;
            flex-flow: row nowrap;

            @include tablet() {
                position: relative;
            }

            &.success {
                text-align: right;
                padding-top: 0;
                display: inline-block;
                position: relative;

                button {
                    background-color: transparent !important;
                    text-align: right;
                    color: $primary !important;
                }
            }

            button {
                text-transform: uppercase;
                line-height: 50px;
                font-size: 1.5em;
                border: none;
                width: 49%;
                font-weight: 700;
                flex: 1;

                &[name="cancel"], &[name="ok"] {
                    color: $cancel;
                    background: transparent;
                    font-weight: 600;
                    display: none;

                    @include tablet() {
                        display: inline-block;
                    }
                }

                &[name="submit"] {
                    color: $white;
                    background: $secondary;
                    border-radius: 2px;
                    width: 100%;
                    font-weight: 700;

                    > i {
                        float: right;
                    }
                }

                &[name="ok"] {
                    flex: 0;
                    float: right;
                    color: $primary;
                    font-size: 1em;
                    width: 200px;
                    display: inline-block;
                }

                &.disabled {
                    background-color: lightgray;
                }
            }
        }
    }
}

.error {
    display: block;
    color: $error;
    margin-left: 1.1em;
    margin-top: 0.3em;

    &.submit-error {
        margin-bottom: 1em;
    }
}

.accepted-formats {
    ._720kb-tooltip-title {
        font-size: 0.8em;
    }

    ul {
        margin: 0.2em 0 0 1.1em;
        font-size: 0.9em;
        padding: 0;
    }
}

.loading {
    text-align: center;

    .loading-dot {
        display: inline-block;
        width: 5px;
        height: 5px;
        background: white;
        border-radius: 50%;
        animation: loading 1.5s ease-in-out infinite;
    }

    .loading-dot:nth-child(4n + 1) {
    }

    .loading-dot:nth-child(4n + 2) {
        animation-delay: .3s;
    }

    .loading-dot:nth-child(4n + 3) {
        animation-delay: .6s;
    }
}

@keyframes loading {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .2;
    }
    100% {
        opacity: 1;
    }
}
